import React, { useState } from "react";
import { useNavigate } from "react-router";

const Login = () => {

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const Auth = () => {
        if(login === process.env.REACT_APP_LOGIN && password === process.env.REACT_APP_PASSWORD){
            localStorage.setItem('merch', 'auth');
            navigate('/orders');
        }else{
            alert('Неправильный логин или пароль');
        }
    }

    return(
        <>
            <div className='flex flex-col border border-black w-fit bg-gray-100 p-10 mx-auto mt-32'>
                <h1 className='font-bold text-center mb-4 uppercase'>Авторизацiя</h1>
                <span>Логiн</span>
                <input className="bg-white px-2 py-1 rounded-lg" placeholder="login" value={login} onChange={(e) => setLogin(e.target.value)}/>
                <span className='mt-2'>Пароль</span>
                <input className="bg-white px-2 py-1 rounded-lg" type="password" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                <button className='mt-8 border border-black py-2' onClick={Auth}>Увiйти</button>
            </div>
        </>
    )
}

export default Login;