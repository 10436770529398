import React from "react";

const Header = () => {

  return (
    <>
      <div className="w-full mx-auto text-black py-4 gap-x-4 flex border-b border-gray-400 px-12">
        <a href="/orders">Замовлення</a>
        <span> | </span>
        <a href="/store">Склад</a>
        {/* <span> | </span>
        <a href="/presale/orders">Передзамовлення</a>
        <span> | </span>
        <a href="/presale/store">Склад передзамовлення</a> */}
      </div>
    </>
  );
};

export default Header;
