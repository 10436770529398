import React, {useState, useEffect} from "react";
import axios from "axios";
import Header from "../components/Header";
import { serverURL } from "../App";
import deleteSVG from "../img/delete.svg";
import { useNavigate } from "react-router";

const Orders = () => {

    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();

    const calcTotal = (orders) => {
        const sizeTotals = {
            xs: 0,
            s: 0,
            m: 0,
            l: 0,
            xl: 0,
            xxl: 0,
            xxxl: 0
        };
        
        orders.forEach(order => {
            for (const [size, quantity] of Object.entries(JSON.parse(order.size))) {
                sizeTotals[size] += quantity;
            }
        });
        
        console.log("Сумма по каждому размеру:");
        for (const [size, total] of Object.entries(sizeTotals)) {
            console.log(`${size.toUpperCase()}: ${total}`);
        }
    }

    const fetchOrders = async () => {
        try {
            const response = await axios.get(`${serverURL}/api/orders`);
            const sortedOrders = response.data.sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt);
            });
            setOrders(sortedOrders);
            //calcTotal(sortedOrders);
        } catch (error) {
            console.error('Ошибка при загрузке заказов:', error);
        }
    };

    useEffect(() => {
        const key = localStorage.getItem('merch');
        if(!key){
            navigate('/')
        }
    }, []);

    useEffect(() => {
        fetchOrders();

        const intervalId = setInterval(fetchOrders, 300000);

        return () => clearInterval(intervalId);
    }, []);

    const changeStatus = (id, value) => {
        axios.patch(`${serverURL}/api/orders/${id}`, { status: value })
            .then(response => {
                fetchOrders();
            })
            .catch(error => {
                console.error("Error updating status", error);
            });
    }

    const deleteOrder = (id) => {
        const confirmed = window.confirm("Вы уверены что хотите удалить заказ? Отменить это действие будет невозможно!");
        if(confirmed){
            axios.delete(`${serverURL}/api/orders/${id}`)
            .then(response => {
                fetchOrders();
            })
            .catch(error => {
                console.error("Error delete", error);
            });
        }
    }


    return(
        <>
            <Header />
            <div className=''>
            {orders.length > 0 ? (
                <table className="w-full mx-auto">
                <thead className='border-b border-gray-300'>
                    <tr className="uppercase">
                            <th class="px-6 py-1">ID замовлення</th>
                            <th class="px-6 py-1">ПIБ</th>
                            <th class="px-6 py-1">Телефон</th>
                            <th class="px-6 py-1">Адреса</th>
                            <th class="px-10 py-1">Размiр</th>
                            <th class="px-6 py-1">Статус</th>
                            <th class="px-6 py-1">Час замовлення</th>
                            <th class="px-6 py-1"></th>
                        </tr>
                </thead>
                <tbody className='text-center'>
                {orders.map(order => (
                            <tr key={order.id} className="items-center border-b border-gray-200">
                                <td class="px-6 py-1">{order.id}</td>
                                <td class="px-6 py-1">{order.clientName}</td>
                                <td class="px-6 py-1">{order.phone}</td>
                                <td class="px-6 py-1">{order.address}</td>
                                <td class="px-6 py-1" dangerouslySetInnerHTML={{
                                    __html: Object.entries(JSON.parse(order.size))
                                        .filter(([key, value]) => value > 0)
                                        .map(([key, value]) => `${key === 'xxl' ? '2XL' : key === 'xxxl' ? '3XL' : key.toUpperCase()}: ${value} шт.`)
                                        .join('<br />')
                                }} />
                                <td class="px-6 py-1">
                                <select value={order.status} onChange={(e) => changeStatus(order.id, e.target.value)}>
                                    <option>Оформлено</option>
                                    <option>Оплачено</option>
                                    <option>Вiдправлено</option>
                                </select>
                                </td>
                                <td class="px-6 py-1">{new Date(order.createdAt).toLocaleString()}</td>
                                <td class="px-6 py-1 cursor-pointer" onClick={() => deleteOrder(order.id)}>
                                    <img src={deleteSVG} alt="delete" width={30}/>
                                </td>

                            </tr>
                        ))}
                </tbody>
            </table>
            ) : (
                <p className="mt-4 text-center">Замовлення вiдсутнi</p>
            )}
        </div>
        </>
    )
}

export default Orders;