import React, {useState, useEffect} from "react";
import axios from "axios";
import Header from "../components/Header";
import { serverURL } from "../App";
import pencil from "../img/pencil.svg";
import { useNavigate } from "react-router";

const Store = () => {

    const [store, setStore] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editProduct, setEditProduct] = useState(null);
    const navigate = useNavigate();

    const fetchStore = async () => {
        try {
            const response = await axios.get(`${serverURL}/api/products`);
            setStore(response.data);
        } catch (error) {
            console.error('Ошибка при загрузке заказов:', error);
        }
    };

    useEffect(() => {
        const key = localStorage.getItem('merch');
        if(key){
            fetchStore();
        }else{
            navigate('/')
        }
    }, []);

    const openModal = (product) => {
        setEditProduct(product);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setEditProduct(null);
        setIsModalOpen(false);
    };

    const handleSave = async (event) => {
        event.preventDefault();
        try {
            const updatedProduct = {
                xs: event.target.xs.value,
                s: event.target.s.value,
                m: event.target.m.value,
                l: event.target.l.value,
                xl: event.target.xl.value,
                xxl: event.target.xxl.value,
                xxxl: event.target.xxxl.value,
            };
            await axios.patch(`${serverURL}/api/products/${editProduct.id}`, updatedProduct);
            closeModal();
            fetchStore();
        } catch (error) {
            console.error(error);
        }
    };

    return(
        <>
        <Header />
        <div className='mt-4'>
        {store.length > 0 ? (
                <>
                    <table className="w-9/12 mx-auto">
                        <thead>
                            <tr>
                                <th>XS</th>
                                <th>S</th>
                                <th>M</th>
                                <th>L</th>
                                <th>XL</th>
                                <th>2XL</th>
                                <th>3XL</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {store.map(item => (
                                <tr key={item.id} className="items-center">
                                    <td>{item.xs} шт.</td>
                                    <td>{item.s} шт.</td>
                                    <td>{item.m} шт.</td>
                                    <td>{item.l} шт.</td>
                                    <td>{item.xl} шт.</td>
                                    <td>{item.xxl} шт.</td>
                                    <td>{item.xxxl} шт.</td>
                                    <td>
                                        <img
                                            className="cursor-pointer"
                                            src={pencil}
                                            alt="Edit"
                                            width={20}
                                            onClick={() => openModal(item)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {isModalOpen && editProduct && (
                        <EditModal
                            product={editProduct}
                            onClose={closeModal}
                            onSave={handleSave}
                        />
                    )}
                </>
            ) : (
                <p className="mt-4 text-center">Товари вiдсутнi</p>
            )}
        </div>
        </>
    )
}

const EditModal = ({ product, onClose, onSave }) => {
    return (
        <div className="fixed w-full h-[50vh] flex items-center justify-center">
            <div className="border border-black p-5 rounded-lg bg-gray-100" onClick={(e) => e.stopPropagation()}>
                <h2 className="text-center mb-2 font-bold">Редагувати товар</h2>
                <form onSubmit={onSave} className='editmodal'>
                    <div className="flex justify-between">
                        <label>XS:</label>
                        <input type="number" name="xs" defaultValue={product.xs} />
                    </div>
                    <div className="flex justify-between">
                        <label>S:</label>
                        <input type="number" name="s" defaultValue={product.s} />
                    </div>
                    <div className="flex justify-between">
                        <label>M:</label>
                        <input type="number" name="m" defaultValue={product.m} />
                    </div>
                    <div className="flex justify-between">
                        <label>L:</label>
                        <input type="number" name="l" defaultValue={product.l} />
                    </div>
                    <div className="flex justify-between">
                        <label>XL:</label>
                        <input type="number" name="xl" defaultValue={product.xl} />
                    </div>
                    <div className="flex justify-between">
                        <label>2XL:</label>
                        <input type="number" name="xxl" defaultValue={product.xxl} />
                    </div>
                    <div className="flex justify-between">
                        <label>3XL:</label>
                        <input type="number" name="xxxl" defaultValue={product.xxxl} />
                    </div>
                    <div className='mt-4 justify-between flex'>
                        <button type="submit" className='border border-black rounded-lg px-2 py-1'>Зберiгти</button>
                        <button type="button" className='border border-black rounded-lg px-2 py-1' onClick={onClose}>Закрити</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Store;