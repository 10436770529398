import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Orders from "./pages/Orders";
import Store from "./pages/Store";
import Login from "./pages/Login";
import PresaleStore from "./pages/PresaleStore";
import PresaleOrders from "./pages/PresaleOrders";
export const serverURL = 'https://merch-k12.com.ua';//'http://localhost:4007';

const App = () => {
  return (
      <BrowserRouter>
        <Routes>
          <Route key="/" path="/" element={<Login />} exact />
            <Route key="/orders" path="/orders" element={<Orders />} exact />
            <Route key="/store" path="/store" element={<Store />} exact /> 
            {/* <Route key="/presaleorders" path="/presale/orders" element={<PresaleOrders />} exact />
            <Route key="/presalestore" path="/presale/store" element={<PresaleStore />} exact />  */}
            <Route key="*" path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
  );
};

export default App;
